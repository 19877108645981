import styled from 'styled-components';


const AppWrapper = styled.div`
.button-container {
  text-align: center;
}
.button {
  background-color: #142743;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
  display: inline-block;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
}

.login-btn {
  a {
    color: #FFF;
    text-decoration: none;
  }
}

.filters {
  align-items: center;
  background-color: lightGray;
  display: flex;
  height: 4rem;
  justify-content: center;
  padding: 1rem;
  position: sticky;
  text-align: center;
  top: 0;
  .stats {
    display: flex;
    justify-content: space-between;
    padding: 3rem;
    width: 50rem;
  }
  .filter {
    display: flex;
  }
  select {
    margin: 0 1rem;
    width: 15rem;
  }
}

.applicants {
  padding: 2rem;
}      

.applicant {
  border: 1px solid;
  margin-bottom: 2rem;
  padding: 2rem;
  p {
    line-height: 1.3;
    margin: 1rem 0;
  }
  span {
    font-weight: bold;
  }

}

.approval {
  display: inline-block;
}
#update {
  color: green; 
  font-weight: 600;
  height: 2rem;
}

//Council Results Page
h2 {
  font-size: 3rem;
  font-weight: 600;
  margin: 2rem 0;
  text-align: center;
  text-transform: uppercase;
}
.results {
  display: flex;
  margin: 0 auto;
  width: 50%;
  flex-direction: column;
  @media only screen and (max-width: 1200px) {
    width: 90%;
  }
  .applicant-votes {
    border: 1px solid;
    display: flex;
    padding: 1rem;
    &:nth-of-type(even) {
      background: lightGray
    }
    .name {
      font-weight: 600;
    }
    .vote {
      font-size: 2rem;
      padding: 0 1rem;
    }
  }
}
`;

export default AppWrapper;