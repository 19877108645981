import React from 'react';
import AppWrapper from '../styles/council.css';
import withContext from '../store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'https://localhost:3006' : 'https://api.hirezstudios.com';

class Council extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      website: undefined,
      applicantData: null,
      activeFilter: undefined,
      platformFilter: undefined,
      approvedStat: 0,
      deniedStat: 0,
      toReviewStat: 0
    }
  }

  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  updateApplicant = (updateOpt, id) => {
    if (updateOpt !== '') {
      fetch(`${apiEnv}/council/updateStatus/${this.state.website}/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: `{
        "approved":"${updateOpt}",
        "jwt": "${this.readCookie('jwt')}"
        }`
      }).then((response) => response.json()).then((response) => {
        document.getElementById('update').innerHTML = 'Updated';
        setTimeout(function () {
          document.getElementById("update").innerHTML = '';
        }, 2000);
        this.allApplicants(this.state.website); 
        this.setState({
          activeFilter: undefined
        })
      })
    } else {
      document.getElementById('response').innerHTML = 'Selection cannot be blank';
    }
  };
  
  allApplicants = (website) => {
    this.setState({
      website: website,
      activeFilter: undefined 
    });
    fetch(`${apiEnv}/council/query/${website}`, {
      method: 'GET',
      headers: {
        "Content-Type": 'application/json'
      },
    }).then((response) => response.json()).then((response) => {
      this.setState({
        applicantData: response
      })
      this.getStats();
    })
  };

  filterApplicant = (a) => {
    if (this.state.activeFilter === 'not-reviewed') {
      if (!a.hasOwnProperty('approved')) {
        return true;
      }
    }
    if (this.state.activeFilter !== undefined && this.state.activeFilter !== 'undefined' ) {
      if (a.approved === this.state.activeFilter) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return true;
   }
  }

  filterConsole = (c) => {
    if (this.state.platformFilter !== undefined && this.state.platformFilter !== 'undefined' ) {
      if (c.platform === this.state.platformFilter) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
   }
  }

  getStats = () => {
    let approved = 0;
    let denied = 0;
    let toDo = 0;
      for (let i = 0; i < this.state.applicantData.length; i++) {
        if (this.state.applicantData[i].approved === 'approved') {
          approved += 1;
        } else if (this.state.applicantData[i].approved === 'denied') {
          denied += 1 ;
        } else {
          toDo += 1;
        }
      }
    this.setState({
      approvedStat: approved,
      deniedStat: denied,
      toReviewStat: toDo
    })
  }

  componentDidMount() {
    if (window.location.hash === "#aoc") {
      this.setState({
        website: 'paladins'
      })
      this.allApplicants('paladins');
    } else {
      this.setState({
        website: 'smite'
      })
      this.allApplicants('smite');
    }
  }

  render() {
    return (
      <AppWrapper>
        {(this.props.context.availableTools && (this.props.context.availableTools.includes('aoc') || this.props.context.availableTools.includes('olympians'))) && 
        <>
        <div id="response"></div>
        {this.state.applicantData !== null &&
        <>
          <div className="filters">
            <div className="filter">
              <div>Status</div>
                <select
                  id="status"
                  onChange={(e) => {this.setState({activeFilter: e.target.value})}}
                  defaultValue={this.state.activeFilter === undefined ? 'selected' : ''}
                >
                  <option value="undefined">Show All</option>
                  <option value="approved">Show Approved</option>
                  <option value="denied">Show Denied</option>
                  <option value="not-reviewed">Not Reviewed</option>
                </select>
            </div>
            <div>Platform</div>
            <select id="platform-select" defaultValue="undefined" onChange={(e) => {this.setState({platformFilter: e.target.value})}}>
              <option value="undefined">Show All</option>
              <option value="pc">PC</option>
              <option value="ps4">PS4</option>
              <option value="xbox">Xbox</option>
              <option value="switch">Switch</option>
            </select>
             <div className="stats">
              <p>Total Approved: {this.state.approvedStat}</p>
              <p>Total Denied: {this.state.deniedStat}</p>
              <p>Total To Review: {this.state.toReviewStat}</p>
              </div>
          </div>
          <div className="applicants">
            {this.state.applicantData.reverse().filter(this.filterApplicant).filter(this.filterConsole).map((applicant) =>
            <div className="applicant" key={applicant._id}>
              <p><span>Name:</span> {decodeURIComponent(applicant.name)}</p>
              <p><span>Username:</span> {decodeURIComponent(applicant.username)}</p>
              <p><span>Hi-Rez Email:</span> {decodeURIComponent(applicant.hrzemail)}</p>
              <p><span>Alternate Email:</span> {decodeURIComponent(applicant.email)}</p>
              <p><span>Country:</span> {decodeURIComponent(applicant.country)}</p>
              {this.state.website === 'smite' &&
                <>
                <p><span>Conquest:</span> {decodeURIComponent(applicant.conquest)}</p>
                  <p><span>God:</span> {decodeURIComponent(applicant.god)}</p> 
                </>
              }
              {this.state.website === 'paladins' &&
                <p><span>Champion:</span> {decodeURIComponent(applicant.champion)}</p>      
              }
              <p><span>Time Played:</span> {decodeURIComponent(applicant.timePlayed)}</p>
              <p><span>platform:</span> {decodeURIComponent(applicant.platform)}</p>
              <p><span>Discord:</span> {decodeURIComponent(applicant.socialDiscord)}</p>
              <p dangerouslySetInnerHTML={{__html: `<span>Mixer:</span> <a href=${decodeURIComponent(applicant.socialMixer)} target="_blank" rel="noopener noreferrer">${decodeURIComponent(applicant.socialMixer)}</a>`}}/>
              <p dangerouslySetInnerHTML={{__html: `<span>Twitch:</span> <a href=${decodeURIComponent(applicant.socialTwitch)} target="_blank" rel="noopener noreferrer">${decodeURIComponent(applicant.socialTwitch)}</a>`}}/>
              <p dangerouslySetInnerHTML={{__html: `<span>Twitter:</span> <a href=${decodeURIComponent(applicant.socialTwitter)} target="_blank" rel="noopener noreferrer">${decodeURIComponent(applicant.socialTwitter)}</a>`}}/>
              <p dangerouslySetInnerHTML={{__html: `<span>YouTube:</span> <a href=${decodeURIComponent(applicant.socialYoutube)} target="_blank" rel="noopener noreferrer">${decodeURIComponent(applicant.socialYoutube)}</a>`}}/>
              <p><span>Question 1:</span> {decodeURIComponent(applicant.q1comments)}</p>
              <p><span>Question 2:</span> {decodeURIComponent(applicant.q2comments)}</p>
              <p><span>Question 3:</span> {decodeURIComponent(applicant.q3comments)}</p>
              <p><span>Question 4:</span> {decodeURIComponent(applicant.q4comments)}</p>
              <div className="approval">
                <div id="update"></div>
                <select id="status"
                  onChange={(e) => this.updateApplicant(e.target.value, applicant._id)}
                  defaultValue={applicant.approved ? applicant.approved : ""}
                >
                  <option disabled value="" >Please Select</option>
                  <option value="approved">Approve</option>
                  <option value="denied">Deny</option>
                </select>
              </div>
            </div>
            )}
          </div>
        </>
        }
        </>
      }
      </AppWrapper>
    );
  }
}

export default withContext(Council);
